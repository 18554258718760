<template>
    <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div class="layout-sidebar" @click="onSidebarClick">
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>

        <div class="layout-main-container">
            <!-- Session Dialog -->
            <Dialog header="Alerta" v-model:visible="displayConfirmation" :style="{width: '350px'}" :modal="true" :closeOnEscape="false" :closable="false" >
                <div class="flex align-items-center justify-content-center">
                    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                    <span>Su sesión ha caducado, por favor ingrese nuevamente</span>
                </div>
                <template #footer>
                    <Button label="Iniciar Sesión" icon="pi pi-check" @click="onConfirmationClicked" class="p-button-text" autofocus />
                </template>
            </Dialog>

            <!-- New Message Dialog -->
            <Dialog :header="this.message.title" v-model:visible="displayMessage" class="message-dialog" :modal="true" :closeOnEscape="false" :closable="false" >
                <div class="flex align-items-center justify-content-center">
                    {{ this.message.body }}
                </div>
                <template #footer>
                    <Button label="Entendido" @click="messageRead" class="p-button-raised p-button-info mr-2 mb-2" />
                </template>
            </Dialog>

            <div class="layout-main">
                <router-view />
            </div>
        </div>
        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
    </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import httpRequest from './service/httpRequest';
import EconActivity from './models/econActivity';
import Business from './models/business';

export default {
    data() {
        return {
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            displayConfirmation: false,
            displayMessage: false,
            showMessage: false,
            message: {},
            menu : [
                {
                    label: 'Inicio',
                    items: [
                        {label: 'Panel de control', icon: 'pi pi-fw pi-chart-bar', to: '/dashboard'},
                    ]
                },
                {
                    label: 'Cuenta',
                    items: [
                        {label: 'Negocio', icon: 'pi pi-fw pi-building', to: '/dashboard/general/informacion-basica'},
                        {label: 'Suscripción', icon: 'pi pi-fw pi-credit-card', to: '/dashboard/general/suscripcion'},
                        {label: 'Configuración', icon: 'pi pi-fw pi-cog', to: '/dashboard/general/configuracion'},
                    ]
                },
                {
                    label: 'General',
                    items: [
                        {label: 'Clientes', icon: 'pi pi-fw pi-id-card', to: '/dashboard/cliente/crud'},
                        {label: 'Productos', icon: 'pi pi-fw pi-box', to: '/dashboard/producto/crud'},
                        {label: 'Proveedores', icon: 'pi pi-fw pi-sitemap', to: '/dashboard/proveedor/crud'},
                    ]
                },
                {
                    label: 'Finanzas',
                    items: [
                        {label: 'Balance', icon: 'pi pi-fw pi-list', to: '/dashboard/balance-saldo'},
                        {label: 'Proformas', icon: 'pi pi-fw pi-file', to: '/dashboard/proforma/crud'},
                        {label: 'Egresos', icon: 'pi pi-fw pi-shopping-bag', to: '/dashboard/compra/crud'},
                        {label: 'Ventas', icon: 'pi pi-fw pi-shopping-cart', to: '/dashboard/venta/crud'},
                    ]
                },
                {
                    label: 'Documentos Electrónicos',
                    items: [
                        {label: 'Guías de Remisión', icon: 'pi pi-fw pi-car', to: '/dashboard/guia-remision/crud'},
                        {label: 'Notas de Crédito', icon: 'pi pi-fw pi-book', to: '/dashboard/nota-credito/crud'},
                    ]
                },
            ]
        }
    }, 
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    async created() {
        // Sync account
        this.syncAccount();
        this.getBroadcastMessage();

        // Setup private routes
        this.isAuthorized = await this.$store.getters.getUser.role === "Administrador";
        if (!this.isAuthorized) {
            this.menu[2].items.splice(1, 1); // Remove product tab
        }
    },
    methods: {
        async onConfirmationClicked() {
            await this.$store.dispatch('Logout');
            this.$router.push('/login');
        },
        async syncAccount() {
            const response = await httpRequest.syncAccount();
            if (response.status === 200) {
                // Store business
                let business = new Business(response.data.business);
                await this.$store.dispatch('setBusiness', business);

                // Store default activity
                let activity = new EconActivity(response.data.defaultActivity);
                await this.$store.dispatch('setDefaultActivity', activity);

                // Store user role
                var user = await this.$store.getters.getUser;
                user.role = response.data.role;
                await this.$store.dispatch('Login', user);
                
                // Store devices
                let hasDevices = response.data.hasDevices;
                await this.$store.dispatch('setDevices', hasDevices)
            }  else if (response.status == 502) { // Unauthorized
                this.displayConfirmation = true;             
            }
        },
        async messageRead() {
            this.displayMessage = false;
            const response = await httpRequest.seenBroadcastMessage(this.message.id);
            if (response.status === 200) {
                this.message = {};
            }
        },
        async getBroadcastMessage() {
            const response = await httpRequest.getBroadcastMessage();
            if (response.status === 200) {
                this.message = response.data;
                this.displayMessage = true;
            }
        },
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        // 'AppFooter': AppFooter,
    }
}
</script>

<style lang="scss">
@import './App.scss';
    @media screen and (max-width: 769px) {
        .message-dialog {
            min-width: 200px;
            max-width: 90%;
        }
    }
    @media screen and (min-width: 769px) {
        .message-dialog {
            min-width: 350px;
            max-width: 500px;
        }
    }


</style>
